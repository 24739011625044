import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import APIService from "../js/common-po-service";
import Utility from "../../../shared/utility";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data: () => ({
    max10Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 10 || "Max Length must be 10 characters or less"],
    stateRule: [(v) => !!v || "Field is required", (v) => /^[a-zA-Z0-9 ]+$/.test(v) || "special character are not allowed"],
    required: [(v) => !!v || "Field is required"],
    emailRule: [(v) => /.+@.+\..+/.test(v) || "Enter valid Email"],
    max20Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 20 || "Max Length must be 10 characters or less"],
    zipcode: [(v) => /^\d{5}(-\d{4})?$/.test(v) || "Enter valid Postal"],
    postalCodeNonUS: [(v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Invalid Postal Code"],
    backSlash: true,
    refresh: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    countryData: [],
    stateData: [],
    backSlashParent: true,
    totalRecords: 0,
    IsActive: true,
    vendorSearch: "",
    vendorId: 0,
    excelName: "",
    disableValidate: true,
    showVendorDialog: false,
    editShowVendorDialog: false,
    title: "",
    lastName: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    selectedState: "",
    postal: "",
    selectedCountry: "",
    phone: "",
    phone2: "",
    fax: "",
    cell: "",
    email: "",
    sh_title: "",
    sh_lastName: "",
    sh_company: "",
    sh_address1: "",
    sh_address2: "",
    sh_city: "",
    sh_selectedState: "",
    sh_postal: "",
    sh_selectedCountry: "",
    sh_phone: "",
    sh_phone2: "",
    sh_fax: "",
    sh_cell: "",
    sh_email: "",
    pay_term_id: "",
    editpay_term_id: "",
    edittaxpayid: "",
    taxpayid: "",
    addAccountNum: "",
    editvendorNum: "",
    editTitle: "",
    editAccountNum: "",
    editLastName: "",
    editCompany: "",
    editAddress1: "",
    editAddress2: "",
    editCity: "",
    editselectedState: "",
    editPostal: "",
    editSelectedCountry: "",
    editPhone: "",
    editPhone2: "",
    editFax: "",
    editCell: "",
    editEmail: "",
    sh_edittitle: "",
    sh_editlastName: "",
    sh_editcompany: "",
    sh_editaddress1: "",
    sh_editaddress2: "",
    sh_editcity: "",
    sh_editselectedState: "",
    sh_editpostal: "",
    sh_editselectedCountry: "USA",
    sh_editphone: "",
    sh_editphone2: "",
    sh_editfax: "",
    sh_editcell: "",
    sh_editemail: "",
    copyBillToData: false,
    copyBillToAddData: false,
    deleteVendorDialog: false,
    editIsActive: false,
    editDisable: true,
    exportDisable: true,
    paymentList: [],
    json_fields: {
      VendorNumber: "vendno",
      Company: "Company",
      Address: "address1",
      Address2: "address2",
      City: "city",
      State: "state",
      ZIP: "zip",
      Country: "country",
      Phone1: "phone1",
      Status: "active",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    headers: [
      {
        text: "Vendor No#",
        value: "vendno",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Company",
        value: "company",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Address",
        value: "address1",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Address 2",
        value: "address2",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "City",
        value: "city",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "State",
        value: "state",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "ZIP",
        value: "zip",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Country",
        value: "country",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Phone 1",
        value: "phone1",
        class: "primary customwhite--text",
        width: "10%",
      },

      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
        width: "5%",
      },
    ],
    vendorList: [],
    addVendorDialog: false,
    panel: 0,
    addVendorBillPanel: true,
    editVendorShipPanel: false,
    addVendorNum: "",
    selectedProject: "",
    editSelectedProject: "",
    projectData: [],
    vendNumValid: false,
    showCountryDrop: false,
    showShipCountryDrop: false,
    showEditCountryDrop: false,
    showShipEditCountryDrop: false,
    stateDataShip: [],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    let countryStateData = await commonAPIService.countryListNew(this.userId, "", 1, "get");
    this.sh_selectedCountry = "USA";
    this.sh_editselectedCountry = "USA";
    this.selectedCountry = "USA";
    this.editSelectedCountry = "USA";
    this.countryData = countryStateData.CountryInfo;
  },
  mounted() {
    this.getOrderTypeData();
  },
  methods: {
    //Add or remove Favourtite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Excel download file name
    fileName() {
      this.excelName = "Vendor_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Reset Function
    resetFunction() {
      this.vendorSearch = "";
      this.IsActive = true;
      //this.getVendorList();
      this.vendorList = [];
      this.totalRecords = 0;
      this.exportDisable = true;
    },
    //Editing the Vendor
    async editItem(item) {
      this.editShowVendorDialog = true;
      this.vendorId = item.vendor_id;
      let response = await APIService.getVendorDataById(item.vendor_id);
      if (response.message !== "NA" && response !== 400) {
        let data = response;
        this.editSelectedCountry = data[0].country;
        this.countrySelected();
        this.editvendorNum = data[0].vendno;
        this.editAccountNum = data[0].acctno;
        this.editTitle = data[0].title;
        this.editLastName = data[0].last;
        this.editCompany = data[0].company;
        this.editAddress1 = data[0].address1;
        this.editAddress2 = data[0].address2;
        this.editCity = data[0].city;
        this.editselectedState = data[0].state;
        this.editPostal = data[0].zip;
        this.editPhone = data[0].phone1;
        this.editPhone2 = data[0].phone2;
        this.editFax = data[0].fax;
        this.editCell = data[0].cell;
        this.editEmail = data[0].email;
        this.sh_editselectedCountry = data[0].rcountry;
        this.countrySelectedShipping();
        this.sh_edittitle = data[0].rtitle;
        this.sh_editlastName = data[0].rlast;
        this.sh_editcompany = data[0].rcompany;
        this.sh_editaddress1 = data[0].raddress1;
        this.sh_editaddress2 = data[0].raddress2;
        this.sh_editcity = data[0].rcity;
        this.sh_editselectedState = data[0].rstate;
        this.sh_editpostal = data[0].rzip;
        this.sh_editphone = data[0].rphone1;
        this.sh_editphone2 = data[0].rphone2;
        this.sh_editfax = data[0].rfax;
        this.sh_editcell = data[0].rcell;
        this.sh_editemail = data[0].remail;
        this.editIsActive = data[0].active == 1 ? true : false;
        this.edittaxpayid = data[0].taxpid;
        this.editpay_term_id = data[0].pterms;
      }
    },
    //Adding bill to shipping
    addCopyBillToShipTo() {
      if (this.copyBillToAddData) {
        this.sh_title = this.title;
        this.sh_lastName = this.lastName;
        this.sh_company = this.company;
        this.sh_address1 = this.address1;
        this.sh_address2 = this.address2;
        this.sh_city = this.city;
        this.sh_selectedState = this.selectedState;
        this.sh_postal = this.postal;
        this.sh_selectedCountry = this.selectedCountry;
        this.sh_phone = this.phone;
        this.sh_phone2 = this.phone2;
        this.sh_fax = this.fax;
        this.sh_cell = this.cell;
        this.sh_email = this.email;
      } else {
        this.sh_title = "";
        this.sh_lastName = "";
        this.sh_company = "";
        this.sh_address1 = "";
        this.sh_address2 = "";
        this.sh_city = "";
        this.sh_selectedState = "";
        this.sh_postal = "";
        this.sh_selectedCountry = "USA";
        this.sh_phone = "";
        this.sh_phone2 = "";
        this.sh_fax = "";
        this.sh_cell = "";
        this.sh_email = "";
      }
    },
    //Copying bill to shipping
    copyBillToToShipTo() {
      if (this.copyBillToData) {
        this.sh_edittitle = this.editTitle;
        this.sh_editlastName = this.editLastName;
        this.sh_editcompany = this.editCompany;
        this.sh_editaddress1 = this.editAddress1;
        this.sh_editaddress2 = this.editAddress2;
        this.sh_editcity = this.editCity;
        this.sh_editselectedState = this.editselectedState;
        this.sh_editpostal = this.editPostal;
        this.sh_editselectedCountry = this.editSelectedCountry;
        this.sh_editphone = this.editPhone;
        this.sh_editphone2 = this.editPhone2;
        this.sh_editfax = this.editFax;
        this.sh_editcell = this.editCell;
        this.sh_editemail = this.editEmail;
      } else {
        this.sh_edittitle = "";
        this.sh_editlastName = "";
        this.sh_editcompany = "";
        this.sh_editaddress1 = "";
        this.sh_editaddress2 = "";
        this.sh_editcity = "";
        this.sh_editselectedState = "";
        this.sh_editpostal = "";
        this.sh_editselectedCountry = "USA";
        this.sh_editphone = "";
        this.sh_editphone2 = "";
        this.sh_editfax = "";
        this.sh_editcell = "";
        this.sh_editemail = "";
      }
    },
    //Validate button click event
    async onClickValidate() {
      if (this.$refs.VendorNumForm.validate()) {
        let validateData = await APIService.validateVendorDataById(this.userId, this.addVendorNum);
        if (validateData.message != "NA") {
          this.vendNumValid = false;
          this.disableValidate = false;
        }
      }
    },
    async getOrderTypeData() {
      let object = {
        UserId: this.userId,
        bu_key: 0,
        pass: 1, // Do not Delete
      };
      let orderTypeData = await APIService.getOrderTypeList("post", object);
      if (orderTypeData?.PaymentTerms) {
        this.paymentList = orderTypeData.PaymentTerms.map((x) => ({
          payterm_id: x.list_id,
          payterm_desc: x.displaystring,
        }));
      }
    },
    //Submit New user details
    async submitAddVendor() {
      if (this.$refs.AddressForm.validate()) {
        let vendObj = {
          vendor_id: 0,
          proj_key: this.selectedProject,
          title: this.title,
          first: "",
          vendno: this.addVendorNum,
          last: this.lastName,
          company: this.company,
          address1: this.address1,
          address2: this.address2,
          city: this.city,
          state: this.selectedState,
          zip: this.postal,
          country: this.selectedCountry,
          phone1: this.phone,
          phone2: this.phone2,
          cell: this.cell,
          email: this.email,
          UserId: this.userId,
          fax: this.fax,
          rtitle: this.sh_title,
          rlast: this.sh_lastName,
          rcompany: this.sh_company,
          raddress1: this.sh_address1,
          raddress2: this.sh_address2,
          rcity: this.sh_city,
          rstate: this.sh_selectedState,
          rzip: this.sh_postal,
          rcountry: this.sh_selectedCountry,
          rphone1: this.sh_phone,
          rphone2: this.sh_phone2,
          rfax: this.sh_fax,
          rcell: this.sh_cell,
          remail: this.sh_email,
          remitasord: 1,
          taxpid: this.taxpayid,
          pterms: this.pay_term_id,
          accountno: this.addAccountNum,
        };
        let obj = {
          VeJson: JSON.stringify(vendObj),
          pass: 2, //Do not remove
        };
        let showMessage = true;
        let response = await APIService.postVendorUpsert("post", obj, showMessage);
        if (response !== null && response !== undefined) {
          this.closeAddVendorDialog();
          this.$refs.AddressForm.resetValidation();
          this.$refs.VendorNumForm.resetValidation();
          this.addVendorDialog = false;
          if (this.vendorList?.length > 0) this.getVendorList();
        }
      }
    },
    //Update vendor details
    async submitEditVendor() {
      if (this.$refs.AddressEditForm.validate()) {
        let vendObj = {
          vendor_id: this.vendorId,
          proj_key: this.editSelectedProject,
          title: this.editTitle,
          first: "",
          vendno: this.editvendorNum,
          last: this.editLastName,
          company: this.editCompany == undefined ? "" : this.editCompany,
          address1: this.editAddress1,
          address2: this.editAddress2,
          city: this.editCity,
          state: this.editselectedState,
          zip: this.editPostal,
          country: this.editSelectedCountry,
          phone1: this.editPhone,
          phone2: this.editPhone2,
          cell: this.editCell,
          email: this.editEmail,
          UserId: this.userId,
          fax: this.editFax,
          rtitle: this.sh_edittitle,
          rlast: this.sh_editlastName,
          rcompany: this.sh_editcompany,
          raddress1: this.sh_editaddress1,
          raddress2: this.sh_editaddress2,
          rcity: this.sh_editcity,
          rstate: this.sh_editselectedState,
          rzip: this.sh_editpostal,
          rcountry: this.sh_editselectedCountry,
          rphone1: this.sh_editphone,
          rphone2: this.sh_editphone2,
          rfax: this.sh_editfax,
          rcell: this.sh_editcell,
          remail: this.sh_editemail,
          pterms: this.editpay_term_id,
          taxpid: this.edittaxpayid,
          active: this.editIsActive == true ? 1 : 0,
          accountno: this.editAccountNum,
        };
        let obj = {
          VeJson: JSON.stringify(vendObj),
          pass: 1, //Do not remove
        };
        let showMessage = true;
        let response = await APIService.postVendorUpsert("post", obj, showMessage);
        if (response !== null && response !== undefined) {
          this.$refs.AddressEditForm.resetValidation();
          this.getVendorList();
          this.editShowVendorDialog = false;
          this.editDisable = true;
        }
      }
    },
    //To get sales order list based on the project
    async getVendorList() {
      this.vendorList = [];
      this.totalRecords = 0;
      let obj = {
        SearchStr: this.vendorSearch == undefined ? "" : this.vendorSearch,
        strActive: this.IsActive,
        proj_key: 0,
        pass: 3,
        UserId: parseInt(this.userId),
      };
      let response = await APIService.postVendorListSearch("post", obj);
      if (response.message !== "NA" && response !== 400) {
        this.vendorList = response.vendorInfo;
        this.totalRecords = this.vendorList.length;
      }
      if (this.totalRecords == 0) {
        this.exportDisable = true;
      } else {
        this.exportDisable = false;
      }
    },
    //Closing Add vendor popup
    closeAddVendorDialog() {
      this.title = "";
      this.lastName = "";
      this.company = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.selectedState = "";
      this.postal = "";
      this.selectedCountry = "";
      this.phone = "";
      this.phone2 = "";
      this.fax = "";
      this.cell = "";
      this.email = "";
      this.sh_title = "";
      this.sh_lastName = "";
      this.sh_company = "";
      this.sh_address1 = "";
      this.sh_address2 = "";
      this.sh_city = "";
      this.sh_selectedState = "";
      this.sh_postal = "";
      this.sh_selectedCountry = "USA";
      this.sh_phone = "";
      this.sh_phone2 = "";
      this.sh_fax = "";
      this.sh_cell = "";
      this.sh_email = "";
      this.addVendorNum = "";
      this.disableValidate = true;
      this.addVendorDialog = false;
      this.copyBillToAddData = false;
      this.addAccountNum = "";
      this.taxpayid = "";
      this.pay_term_id = "";
      this.showCountryDrop = false;
      this.showShipCountryDrop = false;
      this.$refs.AddressForm.resetValidation();
      this.$refs.VendorNumForm.resetValidation();
    },
    //Closing Edit vendor popup
    closeEditVendorDialog() {
      this.editShowVendorDialog = false;
      this.editTitle = "";
      this.editSelectedProject = "";
      this.editLastName = "";
      this.editCompany = "";
      this.editAddress1 = "";
      this.editAddress2 = "";
      this.editCity = "";
      this.editselectedState = "";
      this.editPostal = "";
      this.editSelectedCountry = "";
      this.editPhone = "";
      this.editPhone2 = "";
      this.editFax = "";
      this.editCell = "";
      this.editEmail = "";
      this.sh_edittitle = "";
      this.sh_editlastName = "";
      this.sh_editcompany = "";
      this.sh_editaddress1 = "";
      this.sh_editaddress2 = "";
      this.sh_editcity = "";
      this.sh_editselectedState = "";
      this.sh_editpostal = "";
      this.sh_editselectedCountry = "USA";
      this.sh_editphone = "";
      this.sh_editphone2 = "";
      this.sh_editfax = "";
      this.sh_editcell = "";
      this.sh_editemail = "";
      this.editDisable = true;
      this.copyBillToData = false;
      this.editpay_term_id = "";
      this.edittaxpayid = "";
      this.editAccountNum = "";
      this.showEditCountryDrop = false;
      this.showShipEditCountryDrop = false;
      this.$refs.AddressEditForm.resetValidation();
    },
    //Adding a new customer
    async addVendor() {
      this.selectedState = "";
      this.selectedCountry = "USA";
      let data1 = await commonAPIService.countryListNew(this.userId, this.selectedCountry, 2, "get");
      this.stateData = data1.StateInfo;
      if (this.stateData.length !== 0) {
        this.showCountryDrop = true;
      } else {
        this.showCountryDrop = false;
      }
      this.sh_selectedState = "";
      this.sh_selectedCountry = "USA";
      let data2 = await commonAPIService.countryListNew(this.userId, this.sh_selectedCountry, 2, "get");
      this.stateDataShip = data2.StateInfo;
      if (this.stateDataShip.length !== 0) {
        this.showShipCountryDrop = true;
      } else {
        this.showShipCountryDrop = false;
      }
      this.addVendorDialog = true;
    },
    //Delete the reason
    deleteVendor() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("/po/delete_vendor_by_id?VendId=" + this.vendorId)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.deleteVendorDialog = false;
            this.editShowVendorDialog = false;
            this.editDisable = true;
            this.getVendorList();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.getVendorList();
            this.deleteVendorDialog = false;
            this.editShowVendorDialog = false;
            this.editDisable = true;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Country in add popup once selected
    async countrySelected() {
      if (this.addVendorDialog && this.selectedCountry !== "") {
        this.selectedState = "";
        this.postal = "";
        this.$refs.AddressForm.resetValidation();
        let countryStateData = await commonAPIService.countryListNew(this.userId, this.selectedCountry, 2, "get");
        this.stateData = countryStateData.StateInfo;
        if (this.stateData.length !== 0) {
          this.showCountryDrop = true;
        } else {
          this.showCountryDrop = false;
        }
      } else if (this.editShowVendorDialog && this.editSelectedCountry !== "") {
        this.editselectedState = "";
        this.editPostal = "";
        this.$refs.AddressEditForm.resetValidation();
        let countryStateData = await commonAPIService.countryListNew(this.userId, this.editSelectedCountry, 2, "get");
        this.stateData = countryStateData.StateInfo;
        if (this.stateData.length !== 0) {
          this.showEditCountryDrop = true;
        } else {
          this.showEditCountryDrop = false;
        }
      }
    },
    //Country selected in shipping
    async countrySelectedShipping() {
      if (this.addVendorDialog && this.sh_selectedCountry !== "") {
        this.sh_selectedState = "";
        this.sh_postal = "";
        this.$refs.AddressForm.resetValidation();
        let countryStateData = await commonAPIService.countryListNew(this.userId, this.sh_selectedCountry, 2, "get");
        this.stateDataShip = countryStateData.StateInfo;
        if (this.stateDataShip.length !== 0) {
          this.showShipCountryDrop = true;
        } else {
          this.showShipCountryDrop = false;
        }
      } else if (this.editShowVendorDialog && this.sh_editselectedCountry !== "") {
        this.sh_editselectedState = "";
        this.sh_editpostal = "";
        this.$refs.AddressEditForm.resetValidation();
        let countryStateData = await commonAPIService.countryListNew(this.userId, this.sh_editselectedCountry, 2, "get");
        this.stateDataShip = countryStateData.StateInfo;
        if (this.stateDataShip.length !== 0) {
          this.showShipEditCountryDrop = true;
        } else {
          this.showShipEditCountryDrop = false;
        }
      }
    },
    //To check if its a number of not
    postalCodeFormat(event, num) {
      this.postal = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatShip(event, num) {
      this.sh_postal = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatEdit(event, num) {
      this.editPostal = Utility.formatPostalCode(event, num);
    },
    //To check if its a number of not
    postalCodeFormatEditShip(event, num) {
      this.sh_editpostal = Utility.formatPostalCode(event, num);
    },
  },
  components: {
    breadcrumbComp,
  },
};
